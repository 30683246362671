const PageTitles = {
  default: {
    title:
      "Xposure Music — Personalized Feedback and 1-1 Video Sessions With the Best Music Industry Professionals",
    description:
      "Xposure Music Connects Artists With the Best A&Rs, Marketers, Engineers, Writers, Producers, and Managers From Top Record Labels.",
  },
  landing: {
    title:
      "Xposure Music — Personalized Feedback and 1-1 Video Sessions With the Best Music Industry Professionals",
    description:
      "Xposure Music Connects Artists With the Best A&Rs, Marketers, Engineers, Writers, Producers, and Managers From Top Record Labels.",
  },
  industryPros: {
    title: "Connect with Pros | Xposure Music",
    description:
      "Connect With A&Rs - Marketers - Engineers - Writers - Producers  - Managers.  From Companies Like Columbia, Interscope, Atlantic Records and More.",
  },
  proProfile: {
    title: "%PRO% | Contact %PRO%, %ONELINER%",
    description:
      "Book a live video call with %PRO%, %ONELINER%. Get technical feedback, marketing and career advice.",
  },
  dashboard: {
    title: "Dashboard - Xposure",
    description: undefined,
  },
  myProfile: {
    title: "My Profile - Xposure",
    description: undefined,
  },
  settings: {
    title: "Settings - Xposure",
    description: undefined,
  },
  404: {
    title: "404 - Xposure",
    description: undefined,
  },
  chat: {
    title: "Chat - Xposure",
    description: undefined,
  },
  applyAsIndustryPro: {
    title: "Apply as an Industry Professional - Xposure",
    description: "Send Your Application and Become an Xposure Music Pro.",
  },
  feedback: {
    title: "Feedback Requests - Xposure",
    description: undefined,
  },
  calls: {
    title: "Calls - Xposure",
    description: undefined,
  },
  playlist: {
    title: "Playlist Submissions - Xposure",
    description: undefined,
  },
  music: {
    title: "My Tracks - Xposure",
    description: undefined,
  },
  feedbackTracks: {
    title: "Add a Track - Xposure",
    description: undefined,
  },
  completeYourProfile: {
    title: "Complete your profile - Xposure",
    description: undefined,
  },
  feedbackPayment: {
    title: "Payment - Xposure",
    description: undefined,
  },
  feedbackSuccess: {
    title: "Order Successfully Placed - Xposure",
    description: undefined,
  },
  callId: {
    title: "Call Request Details - Xposure",
    description: undefined,
  },
  feedbackId: {
    title: "Feedback Request Details - Xposure",
    description: undefined,
  },
  submissionId: {
    title: "Playlist Submission Details - Xposure",
    description: undefined,
  },
  login: {
    title: "Login | Xposure Music",
    description:
      "Sign in to your Xposure account to connect with top music executives.",
  },
  signup: {
    title: "Sign Up | Xposure Music",
    description:
      "Set up an Xposure account and send your music directly to the leading executives in the industry.",
  },
};

export default PageTitles;
